import React from 'react';
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Notifications from './Menus/Notifications';
import InvestmentForm from './Menus/InvestmentForm';
import Withdrawal from './Menus/Withdrawal';
import {Histories} from './Request/Admin';
import ManageInvestment from './Menus/ManageInvestment';
import ManageWithdrawals from './Menus/ManageWithdrawals';
import ManageReferral from './Menus/ManageReferral';
import { UpdateText } from './Request/Wallets';
import EditInvestment from './Menus/EditInvestment';
import EditWithdrawal from './Menus/EditWithdrawal';
import Topup from './Menus/Topup';
import Deduct from './Menus/Deduct';
import ManagePlan from './Menus/ManagePlan';
import CreatePlan from './Menus/PlansEdit';
import ManageWallet from './Menus/ManageWallet';
import CreateWallet from './Menus/CreateWallet';
// import { AuthChecker } from './Request/AuthChecker';
import Reinvest from './Menus/Reinvest';
import Reinvestment from './Menus/Reinvestment';
// import Toasts from './Menus/Toasts';
import ManageTransfer from './Menus/ManageTransfer';
import DeleteUser from './Menus/DeleteUser';
import FundingForm from './Menus/FundingForm';
import ManageFunding from './Menus/ManageFunding';
import CreateDapp from './Menus/CreateDapp';

const SecondaryMenus = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const formatStatusMessages = () => {

        window.addEventListener('updatedSuccessStorage', function (e) {
            let successBag  = localStorage.getItem('success');
            if (successBag != null) {
                successBag = JSON.parse(successBag);
                setSuccessMessage(successBag);
            }
        }, false);

        window.addEventListener('updatedErrorStorage', function (e) {
            let errorBag = localStorage.getItem('error');
            if (errorBag != null) {
                errorBag = JSON.parse(errorBag);
                setErrorMessage(errorBag);
            }
        }, false);
       
    }  
    useEffect(() => {
        if(window && window.init_template){
            window.init_template();
        }
        formatStatusMessages();
    });
  return (
    <div>
        <Notifications/>
        <InvestmentForm/>
        <UpdateText/>
        <Withdrawal/>
        <Histories/>
        <ManageInvestment/>
        <ManageWithdrawals/>
        <ManageReferral/>   
        <EditInvestment/>  
        <EditWithdrawal/> 
        <Topup/>
        <Deduct/>
        <ManagePlan/>
        <CreatePlan/>
        <ManageWallet/>
        <CreateWallet/>
        {/* <AuthChecker/> */}
        <Reinvest/>
        <Reinvestment/>
        <ManageTransfer/>
        <DeleteUser/>
        <FundingForm/>
        <ManageFunding/>
        <CreateDapp/>
        {/* <Toasts/> */}
        
        <div className="offcanvas offcanvas-bottom rounded-m offcanvas-detached" id="menu-install-pwa-ios">
            <div className="content">
            <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1656684134/crystalappiconlogo.png" alt="img" width="80" className="rounded-m mx-auto my-4"/>
                <h1 className="text-center">Install Crystal Trade Investment</h1>
                <p className="boxed-text-xl">
                    Install Crystal Trade Investment on your home screen, and access it just like a regular app. Open your Safari menu and tap "Add to Home Screen".
                </p>
                <Link to="#" className="pwa-dismiss close-menu color-theme text-uppercase font-900 opacity-50 font-11 text-center d-block mt-n2" data-bs-dismiss="offcanvas">Maybe Later</Link>
            </div>
        </div>

        <div className="offcanvas offcanvas-bottom rounded-m offcanvas-detached" id="menu-install-pwa-android">
            <div className="content">
                <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1656684134/crystalappiconlogo.png" alt="img" width="80" className="rounded-m mx-auto my-4"/>
                <h1 className="text-center">Install Crystal Trade Investment</h1>
                <p className="boxed-text-l">
                    Install Crystal Trade Investment on your Home Screen to enjoy a unique and native experience.
                </p>
                <Link to="#" className="pwa-install btn btn-m rounded-s text-uppercase font-900 gradient-highlight shadow-bg shadow-bg-s btn-full">Add to Home Screen</Link>
                <Link to="#" data-bs-dismiss="offcanvas" className="pwa-dismiss close-menu color-theme text-uppercase font-900 opacity-60 font-11 text-center d-block mt-n1">Maybe later</Link>
            </div>
        </div> 

        <div id="error-modal" style={{width:"320px"}} className="offcanvas offcanvas-modal offcanvas-detached rounded-m">
          <div className="content text-center">
            <i style={{fontSize:"65px"}} className="scale-box bi bi-x-circle-fill color-red-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block"></i>
            <h1 className="pt-3 font-28">Failed</h1>
            <p className="font-14 mb-4">
              {errorMessage !=='' && <span>{errorMessage.message || errorMessage}</span>}
            </p>
            <Link to='/' data-bs-toggle="offcanvas" data-bs-target="#error-modal" className="close-modal btn btn-full gradient-red shadow-bg shadow-bg-xs">Okay</Link>
          </div>
        </div>

        <div id="success-modal" style={{width:"320px"}} className="offcanvas offcanvas-modal offcanvas-detached rounded-m">
            <div className="content text-center">
                <i style={{fontSize:"65px"}} className="scale-box bi bi-check-circle-fill color-green-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block"></i>
                <h1 className="pt-3 font-28">Successful</h1>
                <p className="font-14">
                    {successMessage !== '' && <span>{successMessage.message}</span>}
                </p>
                <Link to='/' data-bs-toggle="offcanvas" data-bs-target="#success-modal"  className="close-modal btn btn-full bg-success shadow-bg shadow-bg-xs">Okay</Link>
            </div>
        </div>

        <div id="pending-modal" style={{width: "320px"}} className="offcanvas offcanvas-modal offcanvas-detached rounded-m">
		<div className="content text-center">
			<i style={{fontSize:"65px"}} className="scale-box bi bi-question-circle-fill color-blue-dark shadow-s rounded-circle p-0 mt-3 mb-3 d-inline-block"></i>
			<h1 className="pt-3 font-28">Sending...</h1>
			<p className="font-14">
				We are processing this request, please wait!
			</p>
			<Link to="/" data-bs-toggle="offcanvas" data-bs-target="#pending-modal" className="btn btn-full gradient-blue shadow-bg shadow-bg-xs">Close</Link>
		</div>
	</div>

    </div>
  )
}

export default SecondaryMenus