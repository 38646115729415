import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from "react-query";
import apiClient from "../../http-common";

import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';

const VerifyLogin = () => {
    const initialCodes = {
        phoneCode: '',
        emailCode: ''
    }
    const [codes, setCodes] = useState(initialCodes);

    const onChange = (e) =>
    setCodes({ ...codes, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postLogin } = useMutation(      
        async () => {
          return await apiClient.post(`/api/verify-account`, {
            phone_code: codes.phoneCode,
            email_code: codes.emailCode,
          });
        },
        {
          onSuccess: (res) => {              
            setSuccessMessage(res.data);
            triggerSuccess();
            localStorage.setItem('delox-user', JSON.stringify(res.data.user));
            setTimeout(() => {
                window.location.href = '/kyc';
            }, 3000);
            
          },
          onError: (err) => {            
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );


      

    const [timeLeft, setTimeLeft] = useState(60);
    const count = () => {
            let remainder = timeLeft;
            let counter = setInterval(() => {
                if (remainder === 0) {
                    clearInterval(counter)
                }else{
                    remainder -=1;
                    setTimeLeft(remainder);
                }
            }, 1000);
    }
    const reStart = () => {
        setTimeLeft(60);
        count();
    };

    const { isResending: isResendingRequest, mutate: postResend } = useMutation(      
        async () => {
          return await apiClient.post(`/api/retry-verification`);
        },
        {
          onSuccess: (res) => {              
            setSuccessMessage(res.data);
            triggerSuccess();
            reStart();
          },
          onError: (err) => {            
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

    useEffect(() => {
        return count();
    }, [timeLeft])

  return (
    <div className="page-content my-0 py-0">
        <div className="card bg-5 card-fixed">
            <div className="card-center mx-3 px-4 py-4 bg-white rounded-m">
              <div className="text-center d-flex justify-content-center mb-4">
              <img src="https://res.cloudinary.com/dzxyvdq14/image/upload/v1656684134/crystaltradelogowhite3d.png" height="50" alt="logo"/>
                </div>
                <h1 className="font-30 font-800 mb-0 text-center mb-2">Account Verification</h1>
                <p className="text-center text-warning ">Kindly Enter the verification code sent to your provided email and phone number</p>
                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                    <i className="bi bi-envelope font-16"></i>
                    <input onChange={onChange} type="text" name="emailCode" className="form-control text-dark rounded-xs" id="emailCode" placeholder="enter code sent to your email"/>
                    <label htmlFor="emailCode" className="text-dark">Email Verification Code</label>
                    <span>(required)</span>
                </div>
                <div className="form-custom form-label form-border form-icon mb-3 bg-transparent">
                    <i className="bi bi-phone font-16"></i>
                    <input onChange={onChange} type="text" name="phoneCode" className="form-control text-dark rounded-xs" id="phoneCode" placeholder="enter code sent to your phone"/>
                    <label htmlFor="phoneCode" className="text-dark">Phone Verification Code</label>
                    <span>(required)</span>
                </div>
                <div className='text-center small font-weight-bold'>
                    {timeLeft > 0 ? <div> code expires after: {timeLeft}s</div> :<p className='font-weight-bolder text-warning'>
                        {isResendingRequest?<span>Resending code...</span>:<span>This code has expired</span>}
                    </p>}
                </div>
                {timeLeft > 0 ? <div onClick={postLogin} className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-4">
                    {isSendingRequest? <span>Verifying...</span>:<span>Verify Account</span>}
                </div>:
                <div onClick={postResend} className="btn btn-full gradient-green shadow-bg shadow-bg-s mt-4">
                {isResendingRequest? <span>Resending...</span>:<span>Resend code</span>}
                </div>
            
                }                
                <div className="row">
                    <div className="col-6 text-start">
                        <Link to="/register" className="font-14 color-green-light pt-4 d-block">Create Account</Link>
                    </div>
                    <div className="col-6 text-end">
                        <Link to="/login" className="font-14 color-green-light pt-4 d-block">Sign In Account</Link>
                    </div>
                </div>
            </div>
            <div style={{backgroundImage: "url(https://res.cloudinary.com/dzxyvdq14/image/upload/v1675170630/premierbg.png)"}} className="card-overlay rounded-0 m-0 opacity-90 gradient-green">
            </div>
        </div>
    </div>
  )
}

export default VerifyLogin