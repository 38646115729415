import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import {useMediaQuery} from '@react-hook/media-query'
import Analysis from './Analysis';
import { accountBalance, referralBalance, bonusBalance, currentInvestment } from '../Request/Wallets';
// import {currentEarning} from '../Request/Wallets';
import {MiniChart } from "react-ts-tradingview-widgets";
import Chart from 'react-apexcharts'
import InvestmentHistory from '../Investment/InvestmentHistory';
import ChartBalance from '../Reinvestment/ChartBalance';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EarningSlides from '../Investment/EarningSlides';
import Trades from './Trades';

const Index = () => {


    let accountType = localStorage.getItem('user-type');
    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    let theme = localStorage.getItem('CRYSTAL TRADE INVESTMENT-Theme');
    if (theme === 'dark-mode') {
        theme = 'dark'
     }else{
      theme = 'light'
     }
     const [currentTheme, setCurrentTheme] = useState(theme);

    const matches = useMediaQuery('only screen and (max-width: 480px)')
    useEffect(() => {
        document.title = 'DASHBOARD';
        if(window && window.init_template){
            window.init_template();
        } 
        
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('CRYSTAL TRADE INVESTMENT-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark')
                }else{
                   setCurrentTheme('light') 
                }
               
            }             
       }, false);

    });

   return (
<div className='container px-sm-4 px-0 ' style={{marginTop: '40px'}}>    

    {accountType === 'admin' &&
    <div className="card card-style overflow-visible mt-5">
        <div className="mt-5"></div>
        <img style={{width: "180px", height: "180px"}} src={user.profile} alt="profile" className="mx-auto rounded-circle mt-n5 shadow-l"/>

        <h1 className="color-theme text-center font-30 pt-0 pb-2 mb-0">Welcome Admin {user.name}</h1>       
        
    </div>
    }

    {accountType === 'admin' &&
    <div className='text-center'>
        <span className='text-success font-16'>kindly use the navigation menu to access all admin functions, thank you.</span>
    </div>
    }
    
    {accountType !== 'admin' &&
    <EarningSlides/>
    }
    

        {accountType !== 'admin' && 
    <div className="content py-2" style={{marginTop: '10px', marginBottom: '0px'}}>

        <div className="d-flex text-center">
            <div className="me-auto">
                <Link to="/invest" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>analytics</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Trade</h6>
            </div>
            <div className="m-auto">
                <Link to="/withdraw" data-bs-toggle="offcanvas" data-bs-target="#menu-withdrawal" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>money_off_csred</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Withdraw</h6>
            </div>
            <div className="m-auto">
                <Link to="/referral" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>diversity_2</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">Referral</h6>
            </div>
            <div className="ms-auto">
                <Link to="/market" className="icon icon-xxl rounded-circle bg-theme shadow-m color-theme"><i translate="no" className="material-icons-outlined" style={{fontSize: "30px", marginTop: "18px"}}>candlestick_chart</i></Link>
                <h6 className="font-13 opacity-80 font-500 mb-0 pt-2">market</h6>
            </div>
        </div>
    </div> 
    }
{accountType !== 'admin' &&
<>
<Analysis/>
<InvestmentHistory/>
<Trades/>
</>
}

    <div className=''>       
 
        <div className='row mt-2 g-2 px-sm-3 px-0'>
        
        <div className='col-12 col-md-6'>
            <div className='card p-2 shadow-sm color-theme' style={{height: "180px", borderRadius: '15px', overflow: 'hidden'}}>
            <MiniChart symbol= "BINANCE:BTCUSDT"
                height= {180}
                locale= "en"
                dateRange= "12M"
                colorTheme= {currentTheme}
                trendLineColor= "rgba(6, 222, 156, 1)"
                underLineColor= "rgba(136, 241, 195, 0.16)"
                underLineBottomColor= "rgba(41, 98, 255, 0)"
                isTransparent= {true}
                autosize= {false}
                width="100%"/>
            </div>
        </div>
        <div className='col-12 col-md-6'>
        <div className='card p-2 shadow-sm color-theme' style={{height: "180px", borderRadius: '15px', overflow: 'hidden'}}>
            <MiniChart symbol= "BINANCE:ETHUSDT"
                height= {180}
                locale= "en"
                dateRange= "12M"
                colorTheme= {currentTheme}
                trendLineColor= "rgba(6, 222, 156, 1)"
                underLineColor= "rgba(136, 241, 195, 0.16)"
                underLineBottomColor= "rgba(41, 98, 255, 0)"
                isTransparent= {true}
                autosize= {false}
                width="100%"/>
            </div>
        </div>
        </div>
    </div>
</div>
  )
}

export default Index